.cover-section {
  background-color: black;
  background-size: cover;
  background-position: center;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  position: relative;
}

.cover-navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.5);
}

.cover-section h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
}

.cover-section p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.cover-section .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}