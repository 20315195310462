.payment-container {
  padding: 2rem 0;
}

.payment-card {
  border: 1px solid #ced4da;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

.payment-card .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

@media (max-width: 767.98px) {
  .payment-card {
    margin-bottom: 1.5rem;
  }
}